const header = document.querySelector('#header');
const isHome = header.getAttribute('data-ishome') === 'true';

const isApp = document.querySelector('#my-react-app');

const btnsHeader = document.querySelectorAll('.nav__btns a');
const lastBtn = btnsHeader[btnsHeader.length - 1];
const menu = document.querySelector('.nav__menu');
const navigationBar = document.createElement('span');

const moveTo = (left, width) => {
  navigationBar.classList.add('nav__bar--show');
  navigationBar.style.left = `${left}px`;
  navigationBar.style.width = `${width}px`;
};

navigationBar.classList.add('nav__bar');
menu.append(navigationBar);

btnsHeader.forEach((item) => {
  item === lastBtn
    ? item.classList.add('btn', 'btn--brown')
    : item.classList.add('btn', 'btn--white');
});

menu.addEventListener('mouseover', (e) => {
  e.target.tagName === 'A' && moveTo(e.target.offsetLeft, e.target.offsetWidth);
});

menu.addEventListener('mouseleave', () => {
  const currentMenuItems = document.querySelector('.current-menu-item a');
  if (currentMenuItems) {
    const currentMenuItemsOffset = currentMenuItems.offsetLeft;
    const currentMenuItemsWidth = currentMenuItems.offsetWidth;
    moveTo(currentMenuItemsOffset, currentMenuItemsWidth);
  } else {
    navigationBar.classList.remove('nav__bar--show');
  }
});

const hideHeader = () => {
  isHome && header.classList.add('header--black');
  header.classList.add('header--hide');
};

const showHeader = () => {
  isHome && header.classList.remove('header--black');
  header.classList.remove('header--hide');
};

if (!isApp) {
  let lastScrollTop = 0;

  const currentMenuItems = document.querySelector('.current-menu-item a');

  if (currentMenuItems) {
    const currentMenuItemsOffset = currentMenuItems.offsetLeft;
    const currentMenuItemsWidth = currentMenuItems.offsetWidth;
    moveTo(currentMenuItemsOffset, currentMenuItemsWidth);
  }

  window.addEventListener('scroll', () => {
    const st = window.pageYOffset;
    st >= 400
      ? (hideHeader(),
        st < lastScrollTop && header.classList.remove('header--hide'),
        (lastScrollTop = st <= 0 ? 0 : st))
      : showHeader();
  });
}

isApp && header.classList.add('header--nosticky');
